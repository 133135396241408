import favorite from '../../api/favorite'

const PREFIX = 'carenavi.favorites'
const MAX_FAVORITE_LENGTH = 20

export default {
    namespaced: true,
    state: {
        favorites: [],
        noAlert: false,

        items: [],
        isLoaded: false,
        isRequireSync: false,
    },

    mutations: {
        load: function(state, data) {
            let fstorage, 
                favorites = [] // store

            state.isRequireSync = false


            if (!data) {
                data = []
            }

            /*
             * localStorageとapiとの差分をみて、localにあるものを追加
             */
            if (fstorage = localStorage.getItem(`${PREFIX}.favorites`)) {
                let farr = JSON.parse(fstorage)
                for (let f of farr) {
                    if (f.itemId === void 0) continue
                    if (f.limit < (new Date()).getTime()) continue

                    if (data.length == 0) {
                        favorites.push(f)
                        state.isRequireSync = true // 取得apiと差分があるので同期apiが必要
                        continue
                    }


                    let isEqual = false
                    for (let d of data) {
                        if (d.item_id == f.itemId) {
                            isEqual = true
                            break;
                        }
                    }
                    if (!isEqual) {
                        favorites.push(f)
                        state.isRequireSync = true // 取得apiと差分があるので同期apiが必要
                    }
                }
            }

            /*
             * apiからのものは全部後ろ
             */
            for (let d of data) {
                favorites.push({
                    'itemId': d.item_id,
                    'limit': (new Date()).getTime() + 1209600000
                })
            }

            /*
             * 最大を超えたら、後ろから削除
             */
            if (favorites.length > MAX_FAVORITE_LENGTH) {
                let len = favorites.length - MAX_FAVORITE_LENGTH
                for(let i = 0; i < len; i++) {
                    favorites.pop()
                }
            }

            state.favorites = favorites
            localStorage.setItem(`${PREFIX}.favorites`, 
                JSON.stringify(state.favorites))
        },

        initialize: function(state) {
            let fstorage, cstorage
            if (fstorage = localStorage.getItem(`${PREFIX}.favorites`)) {
                let favorites = [], farr = JSON.parse(fstorage)
                for (let f of farr) {
                    if (f.itemId === void 0) continue
                    if (f.limit === void 0) continue
                    if (f.limit < (new Date()).getTime()) continue
                    favorites.push(f)
                }
                state.favorites = favorites
            }
            if (cstorage = localStorage.getItem(`${PREFIX}.noAlert`)) {
                state.noAlert = JSON.parse(cstorage)
            }
        },

        setFavorite: function(state, itemId) {
            let favorites = []
            for (let f of state.favorites) {
                if (f.itemId !== itemId) {
                    favorites.push(f)
                }
            }

            favorites.unshift({
                itemId: itemId,
                limit: (new Date()).getTime() + 1209600000 // +14days
            })

            if (favorites.length > MAX_FAVORITE_LENGTH) {
                favorites.pop()
            }

            state.favorites = favorites

            localStorage.setItem(`${PREFIX}.favorites`, 
                JSON.stringify(state.favorites))
        },

        removeFavorite: function(state, itemId) {
            let favorites = []
            for (let f of state.favorites) {
                if (f.itemId !== itemId) {
                    favorites.push(f)
                }
            }

            state.favorites = favorites

            localStorage.setItem(`${PREFIX}.favorites`, 
                JSON.stringify(state.favorites))
        },

        setNoAlert: function(state, flag) {
            state.noAlert = flag ? true : false
            localStorage.setItem(`${PREFIX}.noAlert`, 
                JSON.stringify(state.noAlert))
        },

        setItems: function(state, items) {
            state.items = items
            state.isLoaded = true
        },

        importLocalFavorites: function(state) {
            let favorites = [], fstorage
            for (let i = 0; i < localStorage.length; i++) {
                try {
            
                let key = localStorage.key(i)
                let k = JSON.parse(localStorage.getItem(key))
                if (k.id !== void 0 && k.area !== void 0 && k.title !== void 0) {
                    favorites.push({
                        itemId: k.id,
                        limit: (new Date()).getTime() + 1209600000,
                    })
                    localStorage.removeItem(key)
                }
            
                } catch (e) {
                    continue;
                } 
            }
            if (favorites.length == 0) return

            if (fstorage = localStorage.getItem(`${PREFIX}.favorites`)) {
                let farr = JSON.parse(fstorage)
                for (let f of farr) {
                    if (f.itemId === void 0) continue
                    if (f.limit < (new Date()).getTime()) continue

                    let isEqual = false
                    for (let d of favorites) {
                        if (d.itemId == f.itemId) {
                            isEqual = true
                            break;
                        }
                    }
                    if (!isEqual) {
                        favorites.push(f)
                    }
                }
            }
            if (favorites.length == 0) return

            localStorage.setItem(`${PREFIX}.favorites`, 
                JSON.stringify(favorites))
        },
    },

    getters: {
        favorites: state => {
            let favorites = []
            for (let f of state.favorites) {
                favorites.push(f.itemId)
            }

            return favorites
        },

        favoritesPost: state => {
            let favorites = []
            for (let f of state.favorites) {
                favorites.push({
                    'item_id': f.itemId
                })
            }

            return favorites
        },

        num: state => {
            return state.favorites.length
        },

        isNoAlert: state => {
            return state.noAlert
        },

        items: state => {
            let items = []
            if (state.isLoaded) {
                let oneDay = 86400000
                let nowTime = (new Date()).getTime()
                items =  [ ...state.items ]
                for (let i of items) {
                    i.daysLeftUntil = 0
                    for (let f of state.favorites) {
                        if (f.itemId === i.item_id) {
                            if (f.limit > nowTime) {
                                let differentTime = f.limit - nowTime
                                i.daysLeftUntil = Math.floor(differentTime / oneDay)
                                if (differentTime % oneDay) {
                                    i.daysLeftUntil++
                                }
                            }
                            break;
                        }
                    }
                }
            }
            return items
        },
    },

    actions: {
        setFavorite: function({commit, dispatch}, itemId) {
            commit('setFavorite', itemId)
            dispatch('initialize')
        },

        /*
         * 削除のときは、手元優先なので、initializeにdispatchしない
         */
        removeFavorite: function({commit, dispatch}, itemId) {
            commit('removeFavorite', itemId)
            if (!isLogin) {
                dispatch('_getguest')
                return
            }

            dispatch('_sync')
        },

        _getguest: function({commit, getters}) {
            favorite.getguest(csrfToken, getters.favoritesPost, res => {
                commit('setItems', res.data[1])
            })
        },

        _sync: function({commit, getters}) {
            favorite.syncitems(csrfToken, getters.favoritesPost, res => {
                commit('setItems', res.data[1])
            })
        },

        initialize: function({commit, state, dispatch, getters}) {
            commit('importLocalFavorites')

            if (!isLogin) {
                commit('initialize')
                dispatch('_getguest')
                return
            }

            favorite.getitems(csrfToken, res => {
                commit('load', res.data[1])
                commit('initialize')

                if (!state.isRequireSync) {
                    commit('setItems', res.data[1])
                    return
                }
                dispatch('_sync')

            }, error => {
                console.log(error)
                commit('initialize')
            })
        }
    }
}

