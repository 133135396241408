export default {
    equalCond (c1, c2) {
        let k1 = Object.keys(c1), k2 = Object.keys(c2)

        if (c1.limit === void 0) return false
        if (k1.toString() !== k2.toString()) return false

        for (let k of k1) {
            if (k === 'limit') continue
            if (c1[k] === '' || c1[k] === null || c1[k].length == 0) {
                if (c2[k] === '' || c2[k] === null || c2[k].length == 0) {
                    continue
                }
                return false
            } else {
                if (Array.isArray(c1[k]) && Array.isArray(c2[k])) {
                    for (let v1 of c1[k]) {
                        let matched = false
                        for (let v2 of c2[k]) {
                            if (v1 == v2) {
                              matched = true
                            }
                        }
                        if (!matched) {
                          return false
                        }
                    }
                } else if (c1[k] !== c2[k]) {
                    return false
                }
            }
        }
        return true
    }
}
