<script>
import commonMixin from '../../mixins/common.js'
export default {
    template: '#template-message',
    data: () => {
        return {
            intervalId: null
        }
    },
    props: [
        'sendmailitems_id'
    ],
    mixins: [
        commonMixin
    ],

    methods: {
        getMessages: async function() {
            await this.$store.dispatch('message/getItems', {
                sendmailitems_id: this.sendmailitems_id,
            })
        },

        postMessage: async function() {
            let message = document.getElementById('messageInput').value
            if (message.length < 1) return

            await this.$store.dispatch('message/post', {
                message: message,
                sendmailitems_id: this.sendmailitems_id,
            })
            
            document.getElementById('messageInput').value = ''
        }
    },

    mounted: function() {
        this.getMessages()

        this.$once('hook:beforeDestroy',  () => {
            clearInterval(this.intervalId)
        })

        this.intervalId = setInterval(() => {
            this.getMessages()
        }, 60000);

        let unsubscribe = this.$store.subscribe((mutation, state) => {
          if (mutation.type == 'message/setItems') {
            this.$nextTick(() => {
              setTimeout(this.scrollDown, 500)
            })
          }
        })
    },

    created: function() {
        document.getElementsByTagName('body')[0].id = 'myPage-message'
    }
}
</script>

