<script>
import commonMixin from '../../mixins/common.js'
export default {
    template: '#template-entries',
    data: () => {
        return {
            intervalId: null
        }
    },
    mixins: [
        commonMixin
    ],

    methods: {
        getEntries: function() {
            this.$store.dispatch('entries/getItems')
        },

        changeEntryState: function($event, sendmailitems_id) {
            let state = $event.target.value
            if (!state) return

            this.$store.dispatch('entries/changeEntryState', {
                state,
                sendmailitems_id
            })
        }
    },

    mounted: function() {
        this.$once('hook:beforeDestroy',  () => {
            clearInterval(this.intervalId)
        })

        this.intervalId = setInterval(() => {
                this.getEntries()
        }, 60000);
    },

    created: function() {
        this.getEntries()
    }
}
</script>


