import messages from '../../api/messages'

export default {
    namespaced: true,
    state: {
        items: {},
        page: 1,
        summary: null,

        lastId: 0,
        isLoaded: false,
    },
    mutations: {
        setItems: function(state, items) {
            state.items = items ? items : {}
            state.isLoaded = true

            Object.keys(state.items).forEach(k => {
              state.lastId = state.items[k][state.items[k].length - 1].id
            })
        },

        setSummary: function(state, summary) {
            state.summary = summary ? summary : {}
        }
    },

    getters: {
        state: state => state,

        isLoaded: state => {
            return state.isLoaded
        },

        isFound: state => {
            return state.isLoaded && state.items
        },

        isNoresult: state => {
            return !state.isLoaded || !state.items
        },

        items: state => {
            return state.isLoaded && state.items !== void 0 ? state.items : null
        },
        
        ownerName: state => {
            return state.isLoaded && state.summary ? state.summary.ownerName : null
        },
        
        itemName: state => {
            return state.isLoaded && state.summary ? state.summary.itemName : null
        },
        
        page: state => {
            return state.page
        },

        lastId: state => {
          return state.lastId
        }
    },

    actions: {
        getItems: function({commit, getters}, pl) {
            messages.get(csrfToken, pl.sendmailitems_id, getters.page, res => {
                commit('setItems', res.data.data)
                commit('setSummary', res.data.summary)
            })
        },

        post: async function({dispatch}, pl) {
            await messages.post(csrfToken, pl.sendmailitems_id, pl.message, res => {
                    console.log(res.data)
            })

            setTimeout(() => {
              dispatch('getItems', {
                sendmailitems_id: pl.sendmailitems_id
              })
            }, 200)
        },

    }
}





