import axios from 'axios'

export default {
    get: function(token, cb, fail) {
        axios({
            method: 'POST', 
            url: '/api/user/history', 
            header: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": token,
            }
        })
        .then(cb)
        .catch(fail)
    },

    sync: function(token, data, cb) {
        axios({
            method: 'PUT', 
            url: '/api/user/history/sync', 
            header: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": token,
            },
            data: {
                data: {
                    search_condition: data
                }
            },
        })
        .then(cb)
        .catch(error => {
            console.log(error)
        })
    },

    clear: function(token) {
        axios({
            method: 'DELETE', 
            url: '/api/user/history/clear', 
            header: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": token,
            },

            data: {
            }
        })
        .then(cb)
        .catch(error => {
            console.log(error)
        })
    }
}



