import axios from 'axios'

export default {
    get: function(token, page, cb) {
        axios({
            method: 'PUT', 
            url: '/api/user/entries?page=' + (page ? page : 1), 
            header: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": token,
            }
        })
        .then(cb)
        .catch((e) => {
            console.log(e)
        })
    },

    changestate: function(token, sendmailitems_id, state, cb) {
        axios({
            method: 'PUT', 
            url: '/api/user/entries/change', 
            header: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": token,
            },
            data: {
                state: state,
                sm_id: sendmailitems_id
            }
        })
        .then(cb)
        .catch((e) => {
            console.log(e)
        })
    }
}





