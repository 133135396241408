import axios from 'axios'

export default {
    get: function(token, cb, fail) {
        axios({
            method: 'PUT', 
            url: '/api/user/scout', 
            header: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": token,
            }
        })
        .then(cb)
        .catch(fail)
    },

    opened: function(token, scout_id, cb) {
        axios({
            method: 'POST', 
            url: '/api/user/scout/opened', 
            header: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": token,
            },
            data: {
                scout_id: scout_id,
            },
        })
        .then(cb)
        .catch(error => {
            console.log(error)
        })
    },

    entry: function(token, scout_id, refuse, cb) {
        axios({
            method: 'POST', 
            url: '/api/user/scout/entry', 
            header: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": token,
            },
            data: {
                data: {
                    scout_id: scout_id,
                    refuse: refuse,
                }
            },
        })
        .then(cb)
        .catch(error => {
            console.log(error)
        })
    }

}




