import {inputFields} from '../config/input-fields'

export default {
    methods: {
        setPage: function() {
            this.$store.commit('result/setPage', this.$route.params.page)
        },

        setSearchCondition: function() {
            this.$store.commit('result/setSearchCondition', this.$route.query)
            this.getSearchResult()
            this.setPageNumTitle()
            window.scrollTo({top:0, behavior: "smooth"})
        },

        getSearchResult: function() {
            this.$store.dispatch('result/getSearchResult')
        },

        setOrder: function($event) {
            this.$store.commit('result/setOrder', $event.target.value)
            this.$router.push({
                name: this.routeName(1),
                params: this.routeParams(1),
                query: this.$route.query,
            })
            this.getSearchResult()
        },

        setNoindex: function () {
            let robot = document.getElementById('app-robot'),
                prev = document.getElementById('app-prev'),
                head = document.getElementsByTagName('head')[0]
            if (robot) robot.remove()
            if (prev) prev.remove()

            robot = document.createElement('meta')
            robot.setAttribute('id', 'app-robot')
            robot.setAttribute('name', 'robots')
            robot.setAttribute('content', 'noindex,nofollow')
            head.appendChild(robot)
        },

        setNoindexNoarchiveFollow: function (page) {
            let robot = document.getElementById('app-robot'),
                head = document.getElementsByTagName('head')[0]
            if (robot) robot.remove()
            if (page < 2) return

            robot = document.createElement('meta')
            robot.setAttribute('id', 'app-robot')
            robot.setAttribute('name', 'robots')
            robot.setAttribute('content', 'noindex,noarchive,follow')
            head.appendChild(robot)
        },

        setPageNumTitle: function () {
            let title = document.getElementsByTagName('title')[0],
              description = document.getElementsByName('description')[0],
              pageNumStr = '', pageNum,
              titleContent, descriptionContent
            pageNum = this.$store.getters['result/page']
            titleContent = title.innerText.replace(/\([0-9]+ページ目\)/, '')
            descriptionContent = description.getAttribute('content').replace(/\([0-9]+ページ目\)/, '')

            pageNumStr = pageNum > 1 ? '(' + pageNum + 'ページ目)' : ''
            title.innerText = pageNumStr + titleContent
            description.setAttribute('content', pageNumStr + descriptionContent)
        },

        getConditionName: function(condition, separator) {
            let name = []
            if (condition.add_area) {
                name.push(condition.add_area)
            }
            if (condition.add_city) {
                name.push(condition.add_city)
            }
            if (condition['job_type'] !== void 0 &&
                condition['job_type'].length) {
                name.push(condition['job_type'].join(separator))
            }
            if (condition['employ_type'] !== void 0 && 
                condition['employ_type'].length) {
                name.push(condition['employ_type'].join(separator))
            }
            if (condition['job_category'] !== void 0 && 
                condition['job_category'].length) {
                name.push(condition['job_category'].join(separator))
            }
            if (condition['options'] !== void 0 && 
                condition['options'].length) {
                name.push(condition['options'].join(separator))
            }
            if (condition.keyword) {
                name.push(condition.keyword)
            }
            if (condition.salary) {
                name.push(condition.salary)
            }

            return name.length > 0 ?  name.join(separator) : '全検索'
        },

        restoreSearchConditionHistory: function() {
            this.$store.dispatch('condition/initialize')
        },

        queryByForm: function() {
            let form = document.forms.form, q = {}
            
            if (!form) {
                return {}
            }

            for (let k of inputFields) {
                let k_ = k.replace('[]', '')
                if (form[k] === void 0) {
                    continue
                }

                let el = form[k]
                if (/^(text|select)/.test(el.type)) {
                    if (el.value) {
                        q[k_] = el.value
                    }
                } else {
                    for (let e of el) {
                        if (/^(checkbox|radio)/.test(e.type) && e.checked) {
                            if (q[k_] !== void 0) {
                                q[k_].push(e.value)
                            } else {
                                q[k_] = [e.value, ]
                            }
                        }
                    }
                }
            }

            return q
        },

        search: function(event) {
            let setDisabled = elements => {
                for (let el of elements) {
                    if (inputFields.indexOf(el.name) < 0) {
                        el.disabled = true
                    }
                };
            }

            this.setHistory()

            if (this.currentRouteName() !== 'result') {
                setDisabled(event.target.getElementsByTagName('input'))
                setDisabled(event.target.getElementsByTagName('select'))
                setDisabled(event.target.getElementsByTagName('textarea'))

                event.target.submit()
                return
            }

            this.$router.push({
                name: 'result',
                params: {
                    pref: this.$store.state.result.category, 
                },
                query: this.queryByForm()
            })

            event.target.blur()
        },

        setHistory: function() {
            this.$store.commit('condition/setHistory', 
                this.queryByForm())
        },

        setFormByConditionHistory: function($event) {
            let selected = $event.target.value, condition

            if (/^h-/.test(selected)) {
                condition = this.$store.getters['condition/histories'][selected.replace(/h-/, '')]
            } else if (/^f-/.test(selected)) {
                condition = this.$store.getters['condition/favorites'][selected.replace(/f-/, '')]
            }

            this.setFormBySearchCondition(condition)

        },

        setFormBySearchCondition: function(q) {
            let form = document.forms.form

            if (!form) return

            if (q === void 0) q = this.$store.getters['result/query']
            if (!q) return

            if (form['condition_history'] !== void 0) {
                form['condition_history'].value = ""
            }

            for (let k of inputFields) {
                if (form[k] === void 0) continue

                if (k === 'add_area' || 
                    k === 'add_city' || 
                    k === 'keyword') {

                    form[k].value = q[k] !== void 0 ? q[k] : ''
                }
                if (k === 'job_type[]' ||
                    k === 'employ_type[]' ||
                    k === 'job_category[]' ||
                    k === 'options[]') {

                    for (let e of form[k]) {
                        e.checked = q[k] !== void 0 && 
                                    q[k] instanceof Array && 
                                    q[k].indexOf(e.value) >= 0 ? 
                                    true : false
                    }
                }
            }
        },

        redirectResult: function($event) {
            let selected = $event.target.value, condition, query = []

            if (/^h-/.test(selected)) {
                selected = selected.replace(/h-/, '')
                condition = this.$store.getters['condition/histories'][selected]
            } else if (/^f-/.test(selected)) {
                selected = selected.replace(/f-/, '')
                condition = this.$store.getters['condition/favorites'][selected]
            }
            delete condition.limit
            query = Object.keys(condition).map(k => {
              let v = condition[k]
              return Array.isArray(v)
              ? v.map(v => k + '=' + v).join('&')
              : k + '=' + v
            })

            window.location.href = '/result?' + query.join('&')
        },

    }
}
