export default {
    methods: {
        setFavorite: function($event, itemId) {
            $event.target.blur()

            if (this.isFavorite(itemId)) {
                $event.preventDefault()
                $event.stopPropagation()
                return
            }
            this.$store.dispatch('favorites/setFavorite', itemId)

            if (this.$store.getters['favorites/isNoAlert']) {
                $event.preventDefault()
                $event.stopPropagation()
                return
            }
        },

        isFavorite: function(itemId) {
            return this.$store.getters['favorites/favorites'].indexOf(itemId) > -1
        },

        toggleFavorite: function($event, itemId) {
            $event.target.blur()

            if (this.isFavorite(itemId)) {
                $event.preventDefault()
                $event.stopPropagation()
                this.$store.dispatch('favorites/removeFavorite', itemId)
                return
            }

            this.setFavorite($event, itemId)
        }
    },

    filters: {
    }
}

