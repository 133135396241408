import ScoutComponent from '../components/scout/base-component'
import ScoutMailComponent from '../components/scout/mail-component'
import EntriesComponent from '../components/entries/base-component'
import EntriesMessageComponent from '../components/entries/message-component'

export default {
    mode: 'history',
    routes: [
        {
            path: "/area/:area/:city/:city2/:page(\\d+)",
            name: "area-city-city2-page",
        },
        {
            path: "/area/:area/:city/:page(\\d+)",
            name: "area-city-page",
        },
        {
            path: "/area/:area/:city/:city2",
            name: "area-city-city2",
        },
        {
            path: "/area/:area/:page(\\d+)",
            name: "area-page",
        },
        {
            path: "/area/:area/:city",
            name: "area-city",
        },
        {
            path: "/area/:area",
            name: "area",
        },
        {
            path: "/company/:company/:area/:page(\\d+)",
            name: "company-area-page",
        },
        {
            path: "/company/:company/:page(\\d+)",
            name: "company-page",
        },
        {
            path: "/company/:company/:area",
            name: "company-area",
        },
        {
            path: "/company/:company",
            name: "company",
        },
        {
            path: "/search/:category/:area/:city/:city2/:page(\\d+)",
            name: "search-area-city-city2-page",
        },
        {
            path: "/search/:category/:area/:city/:page(\\d+)",
            name: "search-area-city-page",
        },
        {
            path: "/search/:category/:area/:city/:city2",
            name: "search-area-city-city2",
        },
        {
            path: "/search/:category/:area/:page(\\d+)",
            name: "search-area-page",
        },
        {
            path: "/search/:category/:area/:city",
            name: "search-area-city",
        },
        {
            path: "/search/:category/:page(\\d+)",
            name: "search-page",
        },
        {
            path: "/search/:category/:area",
            name: "search-area",
        },
        {
            path: "/search/:category",
            name: "search",
        },
        {
            path: "/search/",
            name: "search-form",
        },
        {
            path: "/result/:page(\\d+)",
            name: "result-page",
        },
        {
            path: "/result/",
            name: "result",
        },
        {
            path: "/job/item/preview/:itemId",
            name: "detail-preview",
        },
        {
            path: "/detail/:itemId([^\.]+).html",
            name: "detail",
        },
        {
            path: "/detail_criteo/:itemId([^\.]+).html",
            name: "detail-criteo",
        },
        {
            path: "/detail_indeed/:itemId([^\.]+).html",
            name: "detail-indeed",
        },
        {
            path: "/entry",
            name: "entry",
        },
        {
            path: "/my/scout/:scout_id/mail",
            name: "scout-mail",
            components: {
                main: ScoutMailComponent
            },
            props: {
                main: true,
            },
        },
        {
            path: "/my/favorite/match",
            name: "match",
        },
        {
            path: "/my/favorite/history",
            name: "history",
        },
        {
            path: "/my/favorite",
            name: "favorite",
        },
        {
            path: "/my/scout",
            name: "scout",
            components: {
                main: ScoutComponent
            }
        },
        {
            path: "/my/entries/:sendmailitems_id/message",
            name: "message",
            components: {
                main: EntriesMessageComponent
            },
            props: {
                main: true,
            },
        },
        {
            path: "/my/entries",
            name: "entries",
            components: {
                main: EntriesComponent
            },
        },
        {
            path: "/",
            name: "top",
        },
    ]
}
