import conditions from '../../api/conditions'
import util from '../../util/condition'

const PREFIX = 'carenavi.condition'
const MAX_HISTORY_LENGTH = 5
const MAX_FAVORITE_LENGTH = 10

export default {
    namespaced: true,
    state: {
        histories: [],
        favorites: [],

        match: {},

        noAlert: false,
    },

    mutations: {
        load: function(state, data) {
            let fstorage, favorites = [], mstorage, match

            if (!data) {
                data = {}
                data.favorites = []
                data.match = {}
            }
            favorites = data.favorites
            match = data.match

            if (fstorage = localStorage.getItem(`${PREFIX}.favorites`)) {
                let farr = JSON.parse(fstorage)
                for (let f of farr) {
                    if (f.limit === void 0) continue
                    if (f.limit < (new Date()).getTime()) continue

                    if (data.favorites.length == 0) {
                        favorites.push(f)
                        continue
                    }


                    let isEqual = false
                    for (let d of data.favorites) {
                        if (util.equalCond(f, d)) {
                            isEqual = true
                            break;
                        }
                    }
                    if (!isEqual) favorites.push(f)
                }
            }

            if (favorites.length > MAX_FAVORITE_LENGTH) {
                let len = favorites.length - MAX_FAVORITE_LENGTH
                for(let i = 0; i < len; i++) {
                    favorites.pop()
                }
            }

            state.favorites = favorites
            localStorage.setItem(`${PREFIX}.favorites`, 
                JSON.stringify(state.favorites))
            state.match = match
            localStorage.setItem(`${PREFIX}.match`, 
                JSON.stringify(state.match))
        },

        initialize: function(state) {
            let hstorage, fstorage, cstorage, mstorage

            if (hstorage = localStorage.getItem(`${PREFIX}.histories`)) {
                state.histories = JSON.parse(hstorage)
            }
            if (fstorage = localStorage.getItem(`${PREFIX}.favorites`)) {
                let favorites = [], farr = JSON.parse(fstorage)
                for (let f of farr) {
                    if (f.limit === void 0) continue
                    if (f.limit < (new Date()).getTime()) continue
                    favorites.push(f)
                }
                state.favorites = favorites
            }
            if (cstorage = localStorage.getItem(`${PREFIX}.noAlert`)) {
                state.noAlert = JSON.parse(cstorage)
            }
            if (mstorage = localStorage.getItem(`${PREFIX}.match`)) {
                state.match = JSON.parse(mstorage)
            }
        },

        setHistory: function(state, condition) {
            state.histories.unshift(condition)
            if (state.histories.length > MAX_HISTORY_LENGTH) {
                state.histories.pop()
            }

            localStorage.setItem(`${PREFIX}.histories`, 
                JSON.stringify(state.histories))
        },

        removeHistory: function(state, index) {
            let newData = state.histories.filter((n, i) => i != index)
            state.histories = newData;

            localStorage.setItem(`${PREFIX}.histories`, 
                JSON.stringify(state.histories))
        },

        setFavorite: function(state, condition) {
            let favorites = []

            condition.limit = (new Date()).getTime() + 1209600000 // +14days

            for (let f of state.favorites) {
                if (!util.equalCond(f, condition)) {
                    favorites.push(f)
                }
            }

            favorites.unshift(condition)

            if (favorites.length > MAX_FAVORITE_LENGTH) {
                favorites.pop()
            }
            state.favorites = favorites

            localStorage.setItem(`${PREFIX}.favorites`, 
                JSON.stringify(state.favorites))
        },

        removeFavorite: function(state, condition) {
            let favorites = []

            for (let f of state.favorites) {
                if (!util.equalCond(f, condition)) {
                    favorites.push(f)
                }
            }

            state.favorites = favorites

            localStorage.setItem(`${PREFIX}.favorites`, 
                JSON.stringify(state.favorites))
        },

        setNoAlert: function(state, flag) {
            state.noAlert = flag ? true : false
            localStorage.setItem(`${PREFIX}.noAlert`, 
                JSON.stringify(state.noAlert))
        },

        setMatch: function(state, condition) {
            state.match = condition
            localStorage.setItem(`${PREFIX}.match`, 
                JSON.stringify(state.match))
        }
    },

    getters: {
        histories: state => {
            return state.histories
        },

        favorites: state => {
            return state.favorites
        },

        isNoAlert: state => {
            return state.noAlert
        },

        match: state => {
            return state.match
        }
    },

    actions: {
        initialize: function({commit, state}) {
            if (!isLogin) {
                commit('initialize')
                return
            }

            // commit('initialize')
            conditions.get(csrfToken, res => {
                commit('load', res.data.search_condition)
                commit('initialize')

                conditions.sync(csrfToken, {
                        'favorites': state.favorites, 
                        'match': state.match
                    }, res => {
                })
            }, error => {
                console.log(error)
                commit('initialize')
            })
        },

        removeFavorite: function({commit, state}, condition) {
            commit('removeFavorite', condition)
            conditions.sync(csrfToken, {
                    'favorites': state.favorites, 
                    'match': state.match
                }, res => {
            })
        },

        setMatch: function({commit, state}, match) {
            return new Promise((resolve, reject) => {
                commit('setMatch', match)
                conditions.sync(csrfToken, {
                        'favorites': state.favorites, 
                        'match': state.match
                    }, res => {
                        resolve()
                }, error => {
                    reject()
                })
            })
        }
    }
}
