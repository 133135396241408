import catDict from '../config/category-dict'
import commonUtil from '../util/common'

const WDAYS = ['日', '月', '火', '水', '木', '金', '土']

export default {
    methods: {
        currentPath: function() {
            return this.$router.currentRoute.path
                    .replace(/\d+$/, '')
        },
        parentPath: function() {
            return this.$router.currentRoute.path
                    .replace(/\d+$/, '')
                    .replace(/\/$/, '')
                    .replace(/[^\/]+$/, '')
        },
        currentRouteName: function() {
            let route = this.$route.name
            switch (route) {
            case 'result':
            case 'result-page':
                return 'result'

            case 'area':
            case 'area-page':
                return 'area'
            
            case 'area-city':
            case 'area-city-page':
                return 'area-city'
            case 'area-city-city2':
            case 'area-city-city2-page':
                return 'area-city-city2'
            
            case 'search':
            case 'search-page':
                return 'search'
            
            case 'search-area':
            case 'search-area-page':
                return 'search-area'
            
            case 'search-area-city':
            case 'search-area-city-page':
                return 'search-area-city'

            case 'search-area-city-city2':
            case 'search-area-city-city2-page':
                return 'search-area-city-city2'

            case 'company':
            case 'company-page':
                return 'company'
            
            case 'company-area':
            case 'company-area-page':
                return 'company-area'
            
            case 'detail':
            case 'detail-criteo':
            case 'detail-indeed':
            case 'detail-preview':
                return 'detail'
            
            }
            return route
        },
        areaName: function(route) {
            return route == void 0 || !/^detail/.test(route) ? 
            this.$store.state.result.addArea : this.$store.getters['detail/addArea']
        },
        cityName: function(route) {
            return route == void 0 || !/^detail/.test(route) ? 
            this.$store.state.result.addCity : this.$store.getters['detail/addCity']
        },
        categoryName: function() {
            return catDict.get(this.$store.state.result.category)
        },
        routeParams: function(page) {
            let params = {}

            for (let k of Object.keys(this.$route.params)) {
                params[k] = this.$route.params[k]
            }
            params.page = page !== void 0 ? 
                page : this.$store.getters['result/page']

            return params
        },
        routeName: function(page) {
            let name = this.currentRouteName()
            return page > 1 ? name + '-page' : name
        },
        lastPage: function() {
            return this.$store.state.result.lastPage
        },
        currentPage: function() {
            return this.$store.state.result.page
        },
        currentUri: function() {
            let path = this.$route.fullPath.split("#")
            return path[0] 
        },
        scrollDown: function () {
          let target = document.getElementById(
            this.$store.getters['message/lastId']
          );
          window.scrollTo(0, target.offsetTop - 
              document.getElementById('app-bottomPos').offsetHeight - 10)
        },
        checkMail: function (mail) {
          return mail && mail.length > 0 ? /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) : false
        }
    },

    filters: {
        shortName: function(value) {
            return value.length > 11 ? value.substr(0, 11) + '...' : value
        },

        formatDate: function(value) {
            let dt = commonUtil.dates(value)
            let d = new Date(dt.y, dt.m - 1, dt.d), wday = '（' + WDAYS[d.getDay()] + '）'

            return d.getFullYear() + '年' + 
                   ("0" + (1 + d.getMonth())).slice(-2) + '月' + 
                   ("0" + d.getDate()).slice(-2) + '日' + 
                   wday
        },

        formatDateMd: function(value) {
            let dt = commonUtil.dates(value)
            let d = new Date(dt.y, dt.m - 1, dt.d), wday = '（' + WDAYS[d.getDay()] + '）'

            return ("0" + (1 + d.getMonth())).slice(-2) + '月' + 
                   ("0" + d.getDate()).slice(-2) + '日' + 
                   wday
        },

        formatTime: function(value) {
            let dt = commonUtil.dates(value)

            return ("0" + dt.h).slice(-2) + ':' + 
                   ("0" + dt.i).slice(-2)
        },

        formatDateYmd: function(value) {
            let dt = commonUtil.dates(value)
            let d = new Date(dt.y, dt.m - 1, dt.d)
            return d.getFullYear() + '年' + 
                   ("0" + (1 + d.getMonth())).slice(-2) + '月' + 
                   ("0" + d.getDate()).slice(-2) + '日'
        },

        snipTag: function(value) {
            return value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
        }
    }
}
