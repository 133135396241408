import items from '../../api/items'
import catDict from '../../config/category-dict'
import {inputFields} from '../../config/input-fields'

export default {
    namespaced: true,
    state: {
        category: null,
        area: null,
        city: null,

        addArea: null,
        addCity: null,
        jobType: [],
        employType: [],
        jobCategory: [],
        options: [],
        keyword: null,

        company: null,
        companyName: null,

        items: [],
        indeedItems: [],

        page: 1,
        total: 0,
        lastPage: 1,
        pages: [],
        perPage: 10,

        order: 2,

        isLoaded: false,
        isConditionInitialized: false,
    },
    mutations: {
        setUnloaded: function(state) {
            state.isLoaded = false
        },

        setPage: function(state, page) {
            state.page = page === void 0 ? 1 : page
        },

        setRouteParams: function(state, payload) {
            if (payload.name == 'area') {
                state.area = payload.params.area
            } else if (/^area-city/.test(payload.name)) {
                state.area = payload.params.area
                state.city = payload.params.city
                if (payload.params.city2 !== void 0 && payload.params.city2) {
                    state.city += '/' + payload.params.city2
                }

            } else if (payload.name == 'search') {
                state.category = payload.params.category
            } else if (payload.name == 'search-area') {
                state.category = payload.params.category
                state.area = payload.params.area
            } else if (/^search-area-city/.test(payload.name)) {
                state.category = payload.params.category
                state.area = payload.params.area
                state.city = payload.params.city
                if (payload.params.city2 !== void 0 && payload.params.city2) {
                    state.city += '/' + payload.params.city2
                }

            } else if (payload.name == 'company') {
                state.company = payload.params.company
            } else if (payload.name == 'company-area') {
                state.company = payload.params.company
                state.area = payload.params.area
            }
        },

        setItems: function(state, pl) {
            state.items = pl.data
            state.indeedItems = pl.indeed.indeed_items ? pl.indeed.indeed_items : []
            state.isLoaded = true
        },

        setSearchCondition: function(state, query) {
            let arrfy = v => {
                let values = []
                if (v instanceof Array) {
                    for (let v_ of v) {
                        if (v_) {
                            values.push(v_);
                        }
                    }
                    return values
                } else if (v) {
                    return [v,]
                }
                return []
            }

            let camelize = v => {
                v = v.replace('[]', '')
                return v.replace(/_./g, s => {
                    return s.charAt(1).toUpperCase()
                })
            }

            for (let k of inputFields) {
                if (k === 'add_area' || 
                    k === 'add_city' || 
                    k === 'keyword') {
                    state[camelize(k)] = query[k] !== void 0 ? query[k] : null
                }
                if (k === 'job_type[]' ||
                    k === 'employ_type[]' ||
                    k === 'job_category[]' ||
                    k === 'options[]') {
                    state[camelize(k)] = query[k] !== void 0 ? arrfy(query[k]) : []
                }
            }

            if (state.category) {
                let cat = catDict.get(state.category)

                if (!cat) return

                if (/^employ/.test(state.category)) {
                    state.employType.push(cat)
                } else if (/^job/.test(state.category)) {
                    state.jobType.push(cat)
                } else if (/^facility/.test(state.category)) {
                    state.jobCategory.push(cat)
                } else {
                    state.options.push(cat)
                }
            }

            if (state.area) {
                let cat = catDict.get(state.area)
                state.addArea = cat
            }

            if (state.city) {
                state.addCity = document.getElementsByTagName('body')[0]
                            .getAttribute('data-city');
            }

            if (state.company) {
                state.companyName = document.getElementsByTagName('body')[0]
                            .getAttribute('data-company');
            }

            state.isConditionInitialized = true
        },

        setTotal: function(state, pl) {
            let total = pl.total !== void 0 ? pl.total : 0
            let indeedTotal = pl.indeed.totalByIndeed !== void 0 ? 
                            pl.indeed.totalByIndeed : 0
            state.total = indeedTotal ? indeedTotal : total
        },

        setLastPage: function(state, pl) {
            let lastPage = pl.lastPage !== void 0 ? pl.lastPage : 1
            let last_pageByIndeed = pl.indeed.last_pageByIndeed !== void 0 ? 
                            pl.indeed.last_pageByIndeed : 0
            state.lastPage = last_pageByIndeed ? last_pageByIndeed : lastPage
        },

        setPages: function(state) {
            let cur = state.page * 1, last = state.lastPage * 1, page1, pageL
            page1 = cur - 2 > 0 ? cur - 2 : 1
            pageL = cur + 1 < last ? cur + 1 : last
            pageL = pageL < 4 && last >= 4 ? 4 : pageL
            state.pages = [...Array(pageL - page1 + 1).keys()].map(i => i + page1)
        },

        setPerPage: function(state, perPage) {
            state.perPage = perPage
        },

        setOrder: function(state, order) {
            state.order = order
            state.page = 1
        },
    },

    getters: {
        condition: state => {
            return {
                addArea: state.addArea,
                addCity: state.addCity,
                jobType: state.jobType,
                employType: state.employType,
                jobCategory: state.jobCategory,
                options: state.options,
                keyword: state.keyword,
            }
        },

        page: state => {
            return state.page === void 0 ? 1 : state.page
        },

        query: state => {
            return {
                'add_area': state.addArea,
                'add_city': state.addCity,
                'job_type[]': state.jobType,
                'employ_type[]': state.employType,
                'job_category[]': state.jobCategory,
                'options[]': state.options,
                'keyword': state.keyword
            }
        },

        postQuery: state => {
            let columns = {
                'add_area': state.addArea,
                'add_city': state.addCity,
                'job_type': state.jobType,
                'employ_type': state.employType,
                'job_category': state.jobCategory,
                'options': state.options,
                'search_word': state.keyword,

                'company': state.company,

                'order': state.order
            }, query = {}

            for (let k of Object.keys(columns)) {
                if (columns[k]) {
                    query[k] = columns[k]
                }
            }

            return query
        },

        state: state => state,

        isLoaded: state => {
            return state.isLoaded
        },

        isNoresult: state => {
            return state.items.length == 0 && state.indeedItems.length == 0
        },

        pages: state => {
            return state.isLoaded ? state.pages : []
        },

        order: state => {
            return state.order ? state.order : 2
        },

        items: state => {
            return state.isLoaded ? state.items : []
        },

        indeedItems: state => {
            return state.isLoaded ? state.indeedItems : []
        },

        addArea: state => {
            return state.addArea
        },

        addCity: state => {
            return state.addCity
        },

        companyName: state => {
            return state.companyName
        },

        company: state => {
            return state.company
        },

        category: state => {
            return state.category
        },

        area: state => {
            return state.area
        },

        city: state => {
            return state.city
        },

        areaLink: state => {
            return state.area + (state.city ? '/' + state.city : '')
        },

        isConditionInitialized: state => {
            return state.isConditionInitialized
        },

        from: state => {
            return (state.page - 1) * state.perPage + 1
        },

        to: state => {
            let last = state.page * state.perPage 
            return state.page == state.lastPage ? state.total : last
        },
    },
    actions: {
        getSearchResult: function({commit, getters}) {
            commit('setUnloaded')
            items.getItems(getters.postQuery, getters.page, res => {
                commit('setItems', {
                        data: res.data.data.data, 
                        indeed: res.data.indeed_data
                })
                commit('setTotal', {
                        total: res.data.data.total, 
                        indeed: res.data.indeed_data
                })
                commit('setLastPage', {
                        lastPage: res.data.data.last_page, 
                        indeed: res.data.indeed_data
                })
                commit('setPerPage', res.data.data.per_page)
                commit('setPages')
            })
        }
    }
}
