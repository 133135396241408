<script>
import favoriteMixin from '../../mixins/favorite.js'
export default {
    template: '#template-scout',
    mixins: [
        favoriteMixin,
    ],
}
</script>

