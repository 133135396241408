export default {
    dict: categoryDict,

    get: function(key) {
        return this.dict[key] === void 0 ? null : this.dict[key]
    },

    getKey: function(name) {
        for (let k of Object.keys(this.dict)) {
            if (name == this.dict[k]) {
                return k
            }
        }
    }
}
