const inputFields = [
    'add_area',
    'add_city',
    'job_type[]',
    'employ_type[]',
    'job_category[]',
    'options[]',
    'keyword',
    'salary'
]


export {
    inputFields
}
