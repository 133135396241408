import entries from '../../api/entries'

export default {
    namespaced: true,
    state: {
        items: [],
        owners: [],
        hasNext: false,
        page: 1,

        isLoaded: false,
    },
    mutations: {
        setOwners: function(state, owners) {
            state.owners = owners ? owners : []
        },
        setHasNext: function(state, flag) {
            state.hasNext = flag ? true : false
        },
        setItems: function(state, items) {
            state.items = items ? items : []
            state.isLoaded = true
        },
    },

    getters: {
        state: state => state,

        isLoaded: state => {
            return state.isLoaded
        },

        isFound: state => {
            return state.isLoaded && state.items.length > 0
        },

        isNoresult: state => {
            return state.isLoaded && state.items.length == 0
        },

        items: state => {
            return state.isLoaded && state.items !== void 0 && state.items ? 
                    state.items : null
        },
        
        page: state => {
            return state.page
        },

        ownerName: (state) => (sendmailitems_id) => {
            let owner = state.owners.find(
                owner => owner.sendmailitems_id == sendmailitems_id)
            return owner ? owner.name : null
        }
    },

    actions: {
        getItems: function({commit, getters}) {
            entries.get(csrfToken, getters.page, res => {
                commit('setOwners', res.data.owners)
                commit('setHasNext', res.data.hasNext)
                commit('setItems', res.data.data)
            })
        },

        changeEntryState: async function({dispatch}, pl) {
            await entries.changestate(csrfToken, pl.sendmailitems_id, pl.state, res => {
                console.log(res)
            })
        },
    }
}




