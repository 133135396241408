export default {
    dates: function(value) {
        let dates = value.split(/[ -/:]/)

        return {
            'y': dates[0],
            'm': dates[1],
            'd': dates[2],
            'h': dates[3],
            'i': dates[4],
            's': dates[5],
        }
    }
}
