import scout from '../../api/scout'

export default {
    namespaced: true,
    state: {
        items: [],
        rejected: [],
        userName: null,

        master: [],

        isLoaded: false,
    },
    mutations: {
        setItems: function(state, items) {
            state.items = items.scout !== void 0 ? items.scout : []
            state.rejected = items.rejected !== void 0 ? items.rejected : []

            state.master = items.scout.concat(items.rejected)
            state.isLoaded = true
        },

        setUserName: function(state, name) {
            state.userName = name
        }
    },

    getters: {
        state: state => state,

        isLoaded: state => {
            return state.isLoaded
        },

        isFound: state => {
            return state.isLoaded && state.items.length > 0
        },

        isNoresult: state => {
            return !state.isLoaded || state.items.length == 0
        },

        items: state => {
            return state.isLoaded && state.items !== void 0 && state.items.length > 0 ? 
                    state.items : null
        },
        rejects: state => {
            return state.isLoaded && state.rejected !== void 0 && state.rejected.length > 0 ? 
                    state.rejected : null
        },
        itemsNum: state => {
            return state.isLoaded && state.items !== void 0 && state.items.length > 0 ? 
                    state.items.length : 0
        },

        scout: (state) => (scout_id) => {
            return state.isLoaded && state.master !== void 0 ? 
            state.master.find(item => item.scout_id == scout_id) : null
        },

        userName: state => {
            return state.isLoaded ? state.userName : null
        },

    },

    actions: {
        getItems: function({commit}) {
            scout.get(csrfToken, res => {
                commit('setUserName', res.data[2])
                commit('setItems', res.data[1])
            })
        },
        openMessage: function({commit, getters}, scout_id) {
            scout.opened(csrfToken, scout_id, 2, res => {
                commit('setItems', res.data[1])
            })
        }
    }
}



