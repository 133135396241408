import axios from 'axios'

export default {
    get: function(token, sendmail_id,page, cb) {
        axios({
            method: 'POST', 
            url: '/api/user/message?page=' + (page ? page : 1), 
            header: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": token,
            },
            data: {
                sendmail_id
            }
        })
        .then(cb)
        .catch((e) => {
            console.log(e)
        })
    },

    post: function(token, sendmailitems_id, message, cb) {
        axios({
            method: 'POST', 
            url: '/api/user/message/post', 
            header: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": token,
            },
            data: {
                sm_id: sendmailitems_id,
                data: message
            }
        })
        .then(cb)
        .catch((e) => {
            console.log(e)
        })
    }
}


