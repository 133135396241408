import commonMixin from '../mixins/common.js'

export default {
    el: '#app-header-favorites',
    
    mixins: [
        commonMixin,
    ],

    methods: {
        restoreItemFavorites: function() {
            this.$store.dispatch('favorites/initialize')
        },
    },

    created: function() {
        /*
         * $BJ]B8$5$l$?%-!<%W$r(Bstore$B$K%;%C%H(B
         */
        this.restoreItemFavorites()
    }
}

