import Vue from 'vue'
import VueRouter from 'vue-router'

import "@babel/polyfill"
import 'es6-promise/auto'

import defaultRouter from './routers'
import store from './store'

import VmForm from './vm/form'
import VmHeaderForm from './vm/header-form'
import VmHeaderFormPc from './vm/header-form-pc'
import VmHeaderFavorites from './vm/header-favorites'
import VmHeaderFavoritesPc from './vm/header-favorites-pc'

import commonMixin from './mixins/common.js'

Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.config.devtools = true

// Vue.config.errorHandler = (err, vm, info) => {
//     console.log(err)
//     console.log(info)
// }
// Vue.config.warnHandler = (msg, vm, trace) => {
//     console.log(msg)
//     console.log(trace)
// }
// Vue.config.silent = true

const router = new VueRouter(defaultRouter)


/**
 * HTMLページ上で、自由にjavascriptが配置できるように
 * 範囲の小さいvmに分割する
 */
VmForm.store = store
VmForm.router = router
const formVm = new Vue(VmForm)

VmHeaderForm.store = store
VmHeaderForm.router = router
const headerFormVm = new Vue(VmHeaderForm)
VmHeaderFormPc.store = store
VmHeaderFormPc.router = router
const headerFormPcVm = new Vue(VmHeaderFormPc)

VmHeaderFavorites.store = store
VmHeaderFavorites.router = router
const headerFavoritesVm = new Vue(VmHeaderFavorites)
VmHeaderFavoritesPc.store = store
VmHeaderFavoritesPc.router = router
const headerFavoritesPcVm = new Vue(VmHeaderFavoritesPc)

