import commonMixin from '../mixins/common.js'
import resultMixin from '../mixins/result.js'

export default {
    el: '#app-header-form-pc',
    
    mixins: [
        commonMixin,
        resultMixin,
    ],

    methods: {
        redirectResult: function($event) {
            let selected = $event.target.value, condition, query = []

            if (/^h-/.test(selected)) {
                selected = selected.replace(/h-/, '')
                condition = this.$store.getters['condition/histories'][selected]
            } else if (/^f-/.test(selected)) {
                selected = selected.replace(/f-/, '')
                condition = this.$store.getters['condition/favorites'][selected]
            }
            delete condition.limit
            query = Object.keys(condition).map(k => {
              let v = condition[k]
              return Array.isArray(v)
              ? v.map(v => k + '[]=' + v).join('&')
              : k + '=' + v
            })

            window.location.href = '/result?' + query.join('&')
        },

        restoreSearchConditionHistory: function() {
            this.$store.dispatch('condition/initialize')
        },
    },

    created: function() {
        /*
         * 保存された検索条件をstoreにセット
         */
        this.restoreSearchConditionHistory()
    }
}
