import axios from 'axios'

export default {
    getItems: function(condition, page, cb) {
        axios.post('/api/result?page=' + page, condition)
            .then(cb)
            .catch(error => {
                console.log(error)
            })
    },

    getRecent: function(params, cb) {
        axios.post('/api/recent', params)
            .then(cb)
            .catch(error => {
                console.log(error)
            })
    },

    getItem: function(itemId, cb) {
        axios.get('/api/result/' + itemId)
            .then(cb)
            .catch(error => {
                console.log(error)
            })
    },

    getPreview: function(itemId, cb) {
        axios.get('/api/owner/item/preview/' + itemId)
            .then(cb)
            .catch(error => {
                console.log(error)
            })
    }
}
