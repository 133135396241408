<script>
export default {
    name: 'mail-component',
    template: '#template-scout-mail',

    props: [
        'scout_id'
    ],

    methods: {
        openMessage: function() {
            this.$store.dispatch('scout/openMessage', this.scout_id)
        },
    },

    mounted: function() {
        this.openMessage()
    }
}
</script>


