import Vue from 'vue'
import Vuex from 'vuex'

import result from './modules/result'
import condition from './modules/search-condition'
import favorites from './modules/favorites'
import scout from './modules/scout'
import entries from './modules/entries'
import message from './modules/message'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        result,
        condition,
        favorites,
        scout,
        entries,
        message,
    }
})
