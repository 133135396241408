import commonMixin from '../mixins/common.js'

export default {
    el: '#app-header-favorites-pc',
    
    mixins: [
        commonMixin,
    ],

    methods: {
        restoreItemFavorites: function() {
            this.$store.dispatch('favorites/initialize')
        },
    },

    created: function() {
        /*
         * 保存されたキープをstoreにセット
         */
        this.restoreItemFavorites()
    }
}


